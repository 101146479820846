var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { attrs: { id: "select-company-formed" } }, [
        _vm._m(0),
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "radio-group-container" }, [
            _c(
              "div",
              {
                staticClass: "radio-option",
                on: {
                  click: function ($event) {
                    _vm.companyFormed = "unformed"
                  },
                },
              },
              [
                _c("b-form-radio", {
                  attrs: { value: "unformed" },
                  model: {
                    value: _vm.companyFormed,
                    callback: function ($$v) {
                      _vm.companyFormed = $$v
                    },
                    expression: "companyFormed",
                  },
                }),
                _vm._m(1),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "radio-option d-flex align-items-center",
                on: {
                  click: function ($event) {
                    _vm.companyFormed = "formed with date"
                  },
                },
              },
              [
                _c("b-form-radio", {
                  attrs: { value: "formed with date" },
                  model: {
                    value: _vm.companyFormed,
                    callback: function ($$v) {
                      _vm.companyFormed = $$v
                    },
                    expression: "companyFormed",
                  },
                }),
                _c("div", { staticClass: "text-and-date-container" }, [
                  _vm._m(2),
                  _c(
                    "div",
                    { staticClass: "date-container" },
                    [
                      _c("b-form-input", {
                        staticClass: "formation-date",
                        attrs: { type: "date", max: _vm.dateInputLimit },
                        model: {
                          value: _vm.formationDate,
                          callback: function ($$v) {
                            _vm.formationDate = $$v
                          },
                          expression: "formationDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "radio-option",
                on: {
                  click: function ($event) {
                    _vm.companyFormed = "formed without date"
                  },
                },
              },
              [
                _c("b-form-radio", {
                  attrs: { value: "formed without date" },
                  model: {
                    value: _vm.companyFormed,
                    callback: function ($$v) {
                      _vm.companyFormed = $$v
                    },
                    expression: "companyFormed",
                  },
                }),
                _vm._m(3),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn btn-primary submit-button mt-4",
                attrs: { "aria-label": "submit button", disabled: !_vm.valid },
                on: { click: _vm.submit },
              },
              [_vm._v("\n      Continue\n    ")]
            ),
          ],
          1
        ),
      ])
    : _c("ct-centered-spinner", { staticClass: "mt-5" }, [
        _vm._v("\n  Saving...\n"),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12 mb-3" }, [
      _c("h1", { staticClass: "header-text" }, [
        _vm._v("\n      When did you form your company?\n    "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "radio-text" }, [
      _c("span", [
        _vm._v(
          "\n            I haven't formed my company with the state yet.\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "radio-text" }, [
      _c("span", [
        _vm._v("\n              I formed my company on\n            "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "radio-text" }, [
      _c("span", [
        _vm._v("\n            I can't remember my formation date.\n          "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }